//Internal Lib Import
import { apiService } from "../api/apiService";

export const departmentService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    profileDetails: builder.query({
      query: () => ({
        url: `profile/details`,
        method: "GET",
      }),
    }),
  }),
});
export const { useProfileDetailsQuery } = departmentService;
