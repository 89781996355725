//External Lib Import
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

//Internal Lib Import
import ToastMessage from "../../helpers/ToastMessage";
import { setLogout } from "../slice/authReducer";
import { setLoading } from "../slice/settingReducer";

const BASE_URL = "https://shunnoit.top/support";
// const BASE_URL = process.env.BASE_URL || "http://10.0.0.231:6100";
// const BASE_URL = "http://localhost:5010";
const API_PATH = process.env.API_PATH || "/api/v1";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL + API_PATH,
  prepareHeaders: (headers, { getState }) => {
    const {
      settingReducer: { language },
      authReducer: { accessToken },
    } = getState();
    headers.set("authorization", accessToken ? `Bearer ${accessToken}` : "");
    headers.set("accept-language", language);
    return headers;
  },
  mode: "cors",
  // credentials: 'include',
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  api.dispatch(setLoading(true));
  let result = await baseQuery(args, api, extraOptions);
  const { error, data } = result;

  if (error) {
    api.dispatch(setLoading(false));

    if (error.status === 401) {
      api.dispatch(setLogout());

      ToastMessage.errorMessage(error.data?.message);
    } else if (error.status === 404 || error.status === 400) {
      ToastMessage.errorMessage(error.data?.message);
    } else {
      ToastMessage.errorMessage("Sorry, Something went wrong");
    }
    return { error: { status: error.status, data: error.data } };
  }

  if (data) {
    api.dispatch(setLoading(false));

    if (data?.message) {
      ToastMessage.successMessage(data.message);
      delete data.message;
    }
    return { data };
  }

  return result;
};

export const apiService = createApi({
  reducerPath: API_PATH,
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
