//External import
import { configureStore } from "@reduxjs/toolkit";

//Internal Import
import { apiService } from "./api/apiService";
import authReducer from "./slice/authReducer";
import settingReducer from "./slice/settingReducer";

const store = configureStore({
  reducer: {
    authReducer,
    settingReducer,
    [apiService.reducerPath]: apiService.reducer,
  },

  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([apiService.middleware]),
});

export default store;
