//External Lib  imports
import React from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsIntersect } from "react-icons/bs";
import { RiDashboardLine } from "react-icons/ri";
import { useSelector } from "react-redux";

//Internal Lib  imports
import { AiFillNotification } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { MdPriorityHigh, MdSupportAgent } from "react-icons/md";
import NavItem from "./NavItem";

const SideBar = ({ openMenu, setOpenMenu }) => {
  const { t } = useTranslation();
  const { userDetails } = useSelector((state) => state.authReducer);

  if (userDetails?.role === "proprietor") {
    return (
      <div className={openMenu ? "side-nav-open" : "side-nav-close"}>
        <Nav className="flex-column pt-2">
          <NavItem
            title={t("dashboard")}
            link="/dashboard"
            Icon={RiDashboardLine}
          />
          <NavItem title={t("support")} link="/supports" Icon={BiSupport} />

          <NavItem
            title={t("departments")}
            link="/departments"
            Icon={BsIntersect}
          />

          <NavItem
            title={t("priority")}
            link="/priority"
            Icon={MdPriorityHigh}
          />
          <NavItem
            title={t("notification")}
            link="/notification"
            Icon={AiFillNotification}
          />
          <NavItem
            title={t("messages")}
            link="/messages"
            Icon={AiFillNotification}
          />
          <NavItem title={t("agents")} link="/agents" Icon={MdSupportAgent} />
          {/* <NavItem title={t('role')} link="/role" Icon={BsPersonRolodex} /> */}
        </Nav>
      </div>
    );
  } else {
    return (
      <div className={openMenu ? "side-nav-open" : "side-nav-close"}>
        <Nav className="flex-column pt-2">
          <NavItem
            title={t("dashboard")}
            link="/dashboard"
            Icon={RiDashboardLine}
          />
          <NavItem title={t("support")} link="/supports" Icon={BiSupport} />
          <NavItem
            title={t("assign supports")}
            link="/assign-supports"
            Icon={BiSupport}
          />
          <NavItem
            title={t("messages")}
            link="/messages"
            Icon={AiFillNotification}
          />
        </Nav>
      </div>
    );
  }
};

export default SideBar;
