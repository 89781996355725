//Internal Lib Import
import { apiService } from "../api/apiService";

export const messageService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    messageList: builder.query({
      query: () => ({
        url: `shunno-landing/messages`,
        method: "GET",
      }),
    }),
  }),
});
export const { useMessageListQuery } = messageService;
